/* Friendly Social Media Section */
.social-media {
  min-height: 92vh;
  background-image: url('../images/background.jpg');
  background-size: cover;
  background-position: center;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.img-fluid {
  max-width: 40%;
  height: auto;
  margin-bottom: 20px; /* Add some space below the logo */
}

.social-icon {
  color: #343a40; /* Dark gray color for icons */
  font-size: 3rem;
  margin: 0 15px; /* Space between icons */
  padding: 10px;
  border-radius: 50%; /* Make icons circular */
  background-color: rgba(255, 255, 255, 0.8); /* Light background for icons */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for icons */
  transition: transform 0.3s, background-color 0.3s, color 0.3s; /* Smooth transitions for hover effects */
}

.social-icon:hover {
  transform: scale(1.0); /* Slightly enlarge icons on hover */
  background-color: #1877F2; /* Change background color on hover */
  color: white; /* Change icon color on hover */
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .social-icon {
    font-size: 3rem;
  }

  .img-fluid {
    max-width: 80%;
  }
}
