.footer-text {
    /* background-color: black; */
    background-image: url('../images/background.jpg');
    color: white; /* Black text color */
    /* margin-top: 20px; */
    font-size: 16px;
    text-align: center;
    padding: 0;
    margin: 0;
  }
  
p {
    color: black;
}

/* Footer.css */
.growup-link {
  text-decoration: none; /* Underlined link */
}

.growup-link:hover {
  color: black; /* Lighter color on hover */
  text-decoration: none; /* Underlined link */

}

/* Friendly Footer Section */

  
  .footer a {
    color: #4A90E2; /* Match the icon color for consistency */
    text-decoration: none; /* Remove underline from links */
    font-size: 20px;
    font-family: STC;
    min-height: 10vh;
  }
  
  .footer a:hover {
    text-decoration: underline; /* Underline on hover for clarity */
  }
  
  .footer-text {
    text-align: center; /* Center the footer text */
  }